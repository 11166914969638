/**
 * Script to set up common features for each view of the website.
 * @author See list of contributors {@link https://gitlab.com/codinsa/plateforme/site-infra/-/graphs/master?ref_type=heads}
 * @license GPL-3.0-or-later
 */
import $ from 'jquery';
import '../../css/layout.css';

/** Function to set up common features for each view of the website */
function setUpCommon() {
    $('.scroll-top').on('click', (event) => {
        event.preventDefault();
        $('html, body').animate({ scrollTop: 0 }, 600);
    });

    $(window).on('scroll', (event) => {
        if ($(event.target).scrollTop() > Math.round(window.innerHeight / 100) * 150) {
            $('.scroll-top').fadeIn(600);
        } else {
            $('.scroll-top').fadeOut(600);
        }
    });
}

$(setUpCommon);

export default setUpCommon;
